// @ts-check
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';

import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import CalibratedDocumentReviewersTable from '@/components/DocumentReviewSessions/CalibratedDocumentReviewersTable';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">List of Calibrated Document Reviewers</Header>;
};

const ListCalibratedDocumentReviewers = () => (
  <CrossTableContextProvider>
    <ContentLayout header={<PageHeader />}>
      <CalibratedDocumentReviewersTable />
    </ContentLayout>
  </CrossTableContextProvider>
);

export default ListCalibratedDocumentReviewers;
