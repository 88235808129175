import type { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import {
  AuditTrailRecordType,
  Density,
  PathDefinitionType,
  PeerReviewStatus,
  PeerReviewType,
  PromoPathStatus,
  UserProfileStatus,
  VisualMode,
  WorkSummaryStatus,
} from '@/api/API';
import { AuthGroup } from '../resources/rbac-constants';

export type Nullable<T> = T | null | undefined;

export type OptionalString = Nullable<string>;
export type OptionalNumber = Nullable<number>;
export type OptionalBool = Nullable<boolean>;

export type BrowserState = Nullable<Record<string, Nullable<string | string[] | Item | Item[]>>>;

interface ToolsContentSetterParams {
  sectionKey?: string;
  content?: JSX.Element;
}

export interface ToolsContentSetter {
  (params: ToolsContentSetterParams): void;
}

interface PeopleProfileBase {
  alias: string;
  email: string;
  firstName?: OptionalString;
  isManager: boolean;
  jobLevel: number;
  lastName?: OptionalString;
  name?: string;
}

interface PeopleManager {
  alias: string;
  name: string;
}

export interface DirectReportRecord extends PeopleProfileBase {
  hireDate: Nullable<Date>;
}

export interface PeopleProfileRecord extends PeopleProfileBase {
  jobTitle: string;
  manager: PeopleManager;
  orgName?: string;
}

export interface PromoUserProfile extends PeopleProfileBase {
  jobTitle: string;
  manager: PeopleManager;
  name: string;
  orgName: string;
  personId: string;
  identityId?: OptionalString;
  hasLoggedIn?: boolean;
  hireDate?: OptionalString;
  groups?: AuthGroup[];
  isValidUser?: boolean;
  version?: number;
  status?: UserProfileStatus;
  preferences?: {
    visualMode: Nullable<VisualMode>;
    density: Nullable<Density>;
  };
}

export type PromoNotification = FlashbarProps.MessageDefinition & {
  timestamp?: number;
};

export enum ViewerType {
  CANDIDATE,
  MANAGER,
  PEER_REVIEWER,
}

/** A generic item. All resource/object models should extend this. */
export interface Item {
  id: string;
}

interface PromoPathBase extends Item {
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  name: string;
  startLevel: number;
  targetLevel: number;
  allowsAttachments: boolean;
  suggestedLPs: string[];
  role: string;
  version: number;
  status: PromoPathStatus;
  type: PathDefinitionType;
}

export interface ManagerPathItem extends PromoPathBase {
  managerAlias: string;
  candidateCount: number;
  type: PathDefinitionType.MANAGER;
}

/** Full `PromoPath` template resource with all fields. Used in create/view/edit */
export interface ManagerPathResource extends ManagerPathItem {
  categories: CategoryResource[];
  totalRequired?: OptionalNumber;
}

export interface CandidatePathItem extends PromoPathBase {
  candidate: string;
  manager: string;
  targetQuarter: string;
  totalCompleted?: OptionalNumber;
  totalRequired?: OptionalNumber;
  progressPct?: OptionalNumber;
  type: PathDefinitionType.CANDIDATE;
}

export interface CandidatePathResource extends CandidatePathItem {
  categories: CategoryResource[];
}

/** Base `Category` with key fields. Description is optional here. Used for listing or pulling back in relationships */
export interface CategoryItem extends Item {
  label: string;
  description?: string;
  version?: number;
}

/** Full `Category` with all fields. Used when working with `PromoPath` and `CandidatePromoPath` */
export interface CategoryResource extends CategoryItem {
  description: string;
  isDefault: boolean;
}

/** Base `FileRecord` returned when listing files or retrieving with a `WorkSummary` */
export interface FileRecordItem extends Item {
  name: string;
  size: number;
  version: number;
  uploadedAt: Nullable<Date>;
  fileDateModified: Nullable<Date>;
}

/** Full `FileRecord` - includes s3Key. Used in creating/downloading. */
export interface FileRecordResource extends FileRecordItem {
  s3Key: string;
  storageIdKey: string;
  ownerAlias: string;
  workSummaries: { id: string; title: string }[];
}

export interface FileRecordAttachment extends FileRecordItem {
  fileAttachmentId?: string;
}

/** Base `WorkSummary` used in listing / collections. Doesn't include certain large/expensive fields */
export interface WorkSummaryBase extends Item {
  alias: string;
  title: string;
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  status: WorkSummaryStatus | keyof typeof WorkSummaryStatus;
  workSummaryType: string;
  statusReason: string | undefined;
  promoPathId: OptionalString;
  submittedAt: Nullable<Date>;
  version: number;
  categories: CategoryItem[];
  coOwners: Set<string>;
}

export interface WorkSummaryItem extends WorkSummaryBase {
  commentCount: number;
}

export interface WorkSummaryAdminItem extends WorkSummaryBase {
  reviewerAlias: string | undefined;
  managerAlias: string | undefined;
  candidatePromoPathVersion: number;
}

export interface CandidateWorkSummaryItem extends WorkSummaryItem {
  name: string;
}

/** Full `WorkSummary` - includes all fields. Used for create/update/view */
export interface WorkSummaryResource extends WorkSummaryBase {
  content: string;
  contentVersion: number;
  leadershipPrinciples: string[];
  documents: FileRecordAttachment[];
  peerReviewers: { alias: string; name: string; id: string; version: number }[];
}

export interface PeerReviewBase extends Item {
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  requiredBy: Nullable<Date>;
  reviewerAlias: string;
  content: string;
  reviewType: Nullable<PeerReviewType>;
  reviewStatus: PeerReviewStatus;
  version: number;
}

export interface PeerReviewItem extends PeerReviewBase {
  workSummaryId: string;
  workSummaryTitle: OptionalString;
  candidateAlias: OptionalString;
  candidateManagerAlias: OptionalString;
  commentCount: number;
  reviewerName: OptionalString;
  reviewedVersion: OptionalNumber;
  latestVersion: OptionalNumber;
}

export interface PeerReviewResource extends PeerReviewItem {
  workSummaryDetail: string;
  workSummaryType: OptionalString;
  workSummaryCategories: CategoryItem[];
  candidatePromoPathTargetLevel: OptionalNumber;
  candidatePromoPathName: OptionalString;
  documents: (FileRecordItem & { workSummaryFileId?: OptionalString })[];
}

export interface PeerReviewRequest {
  userProfileExists: boolean;
  alias?: string;
  name?: string;
  email?: string;
  manager?: string;
  isManager?: boolean;
  jobLevel?: number;
  reviewType?: PeerReviewType | null | undefined;
  isVerified: boolean;
  requestId?: string;
  requestStatus: PeerReviewStatus;
  version?: number;
}

export interface CommentItem extends Item {
  authorAlias: string;
  authorName: string;
  content: string;
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  workSummaryId?: string | null | undefined;
  version: number;
}

export interface AuditTrailItem extends Item {
  modifiedByAlias: string;
  humanreadablelog: string[];
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  recordType: AuditTrailRecordType;
  recordId: string;
}

export interface PromoReadinessBase {
  alias: string;
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  id: string,
  certList?: Array< string > | null,
  techPillar?: Array< string > | null,
  tfcMembership: string,
  rating: string,
  feedbackProviders?: Array< string > | null,
  otherContributions: string,
  lastModifiedBy: string
}