export const enum SESSION_STATES {
  SCHEDULED = 'Scheduled',
  FINAL_VOTE = 'Final vote',
  COMPLETE = 'Complete',
}

export enum Vote {
  Yes = 'Yes',
  No = 'No',
}

export interface UserVote {
  documentReady: Vote | null;
  candidateReady: Vote | null;
}

export interface Candidate {
  name: string;
  role: string;
  level: string;
  tenure: string;
  currentManager: string;
}

export interface Reviewer {
  id: string;
  label: string;
  documentReadyVote: string | null;
  candidateReadyVote: string | null;
}

export interface SessionData {
  id: string;
  state: SESSION_STATES;
  candidate: Candidate;
  reviewers: Reviewer[];
  workdocsLink: string;
  chimeLink?: string;
  date: string;
  time: string;
  timezone: { label: string; value: string };
  creator: { id: string; label: string };
}
