import { SpaceBetween, TextContent, Box } from '@amzn/awsui-components-react';
import { SessionData } from '../config';

const DocInfo: React.FC<{ sessionData: SessionData }> = ({ sessionData }) => (
  <SpaceBetween size="xs">
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Workdocs Link:
      </Box>{' '}
      <a href={sessionData.workdocsLink} target="_blank" rel="noopener noreferrer">
        {sessionData.workdocsLink}
      </a>
    </TextContent>
    {sessionData.chimeLink && (
      <TextContent>
        <Box fontWeight="bold" variant="span">
          Chime Link:
        </Box>{' '}
        <a href={sessionData.chimeLink} target="_blank" rel="noopener noreferrer">
          {sessionData.chimeLink}
        </a>
      </TextContent>
    )}
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Date/Time:
      </Box>{' '}
      {sessionData.date} {sessionData.time} {sessionData.timezone.label}
    </TextContent>
  </SpaceBetween>
);

export default DocInfo;
