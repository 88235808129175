// @ts-check
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import PanelReviewSessionsTable from '@/components/common/PanelReviewSessions/PanelReviewSessionsTable/PanelReviewSessionsTable';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">List of Panel Review Sessions</Header>;
};

const ListPanelReviewSessions = () => (
  <CrossTableContextProvider>
    <ContentLayout header={<PageHeader />}>
      <PanelReviewSessionsTable />
    </ContentLayout>
  </CrossTableContextProvider>
);

export default ListPanelReviewSessions;
