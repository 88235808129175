import type {
  PropertyFilterOperatorExtended,
  PropertyFilterOption,
  PropertyFilterProperty,
} from '@amzn/awsui-collection-hooks';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import StatusIndicator, { type StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator';
import {
  HeaderActionSetParams,
  WorkSummaryItem,
  TableHeaderParams,
  PromoTableDefinition,
  ActionDefinition,
  CategoryItem,
  CandidatePathResource,
  ItemAction,
  Nullable,
} from '@/models';
import { WorkSummaryStatus } from '@/api/API';
import { RESOURCES } from '@/common/constants';
import { useAppContext } from '@/contexts/AppContext';
import TableHeader from '@/components/common/table/TableHeader';
import { getGroupValueLabel, getLabelFromEnum, getNameDefinition } from '@/common/labels';
import { PromoButton, PromoButtonDropdown } from '@/components/common/Buttons';
import WorkSummaryProgress from '@/components/common/WorkSummaryProgress';
import { CreateWorkSummaryPage, EditWorkSummaryPage, ReviewWorkSummaryPage } from '@/common/pages';

const Resource = RESOURCES.WORKSUMMARY;
const ResourceName = getNameDefinition(Resource);

/** Params - {@link HeaderActionSetParams} */
export const ActionSet = ({ selectedItem, actionDefs }: HeaderActionSetParams<WorkSummaryItem>) => {
  const { currentUser, spoofUser } = useAppContext();
  let canRequestReview = selectedItem?.status === WorkSummaryStatus.DRAFT;
  let canRecall = [WorkSummaryStatus.MANAGER_REVIEW, WorkSummaryStatus.PEER_REVIEW].includes(
    selectedItem?.status as WorkSummaryStatus
  );
  if ((spoofUser?.alias ?? currentUser?.alias) !== selectedItem?.alias) {
    canRequestReview = selectedItem?.status === WorkSummaryStatus.MANAGER_REVIEW;
    canRecall = selectedItem?.status === WorkSummaryStatus.MANAGER_PEER_REVIEW;
  }
  const actionToIsDisabled: Partial<{ [K in ItemAction]: boolean }> = {
    create: false,
    edit: !selectedItem || selectedItem.status !== WorkSummaryStatus.DRAFT,
    'request peer review': !selectedItem || !canRequestReview,
    'return to candidate': !selectedItem || selectedItem.status !== WorkSummaryStatus.MANAGER_REVIEW,
    delete: !selectedItem || selectedItem.status !== WorkSummaryStatus.DRAFT,
    submit: !selectedItem || selectedItem.status !== WorkSummaryStatus.DRAFT || !selectedItem.promoPathId,
    approve:
      !selectedItem ||
      ![WorkSummaryStatus.MANAGER_PEER_REVIEW, WorkSummaryStatus.MANAGER_REVIEW].includes(
        selectedItem.status as WorkSummaryStatus
      ),
    review:
      !selectedItem ||
      ![
        WorkSummaryStatus.MANAGER_REVIEW,
        WorkSummaryStatus.PEER_REVIEW,
        WorkSummaryStatus.MANAGER_PEER_REVIEW,
      ].includes(selectedItem.status as WorkSummaryStatus),
    recall: !selectedItem || !canRecall,
  };
  const actions: ItemAction[] = [
    'edit',
    'review',
    'return to candidate',
    'request peer review',
    'approve',
    'submit',
    'recall',
    'delete',
  ];
  const createAction = actionDefs.find((actionDef) => actionDef.action === 'create');
  // Action buttons have a specific order of precedence, which the caller doesn't need to know about
  // so deliberately order based on the setup specific to this action set.
  const orderedActionDefs: ActionDefinition[] = [];
  actions.forEach((action) => {
    let actionDef = actionDefs.find((def) => def.action === action);
    if (actionDef) {
      if (['edit', 'review'].includes(actionDef.action)) {
        actionDef = {
          ...actionDef,
          actionParams: {
            ...actionDef.actionParams,
            itemId: selectedItem?.id,
          },
        };
      }
      orderedActionDefs.push(actionDef);
    }
  });

  return (
    // Dynamically set the button state based on whether an item from the table is selected
    <SpaceBetween direction="horizontal" size="s">
      <PromoButtonDropdown
        resourceName={ResourceName}
        isDisabled={!selectedItem}
        actionToIsDisabled={actionToIsDisabled}
        actions={orderedActionDefs}
      />
      {createAction && (
        <PromoButton
          action={createAction.action}
          isDisabled={actionToIsDisabled[createAction.action]}
          resourceName={ResourceName}
          targetType={createAction.targetType || 'function'}
          actionParams={createAction.actionParams}
        />
      )}
    </SpaceBetween>
  );
};

export function getCreateAction(path: Nullable<CandidatePathResource>): ActionDefinition {
  return {
    action: 'create',
    targetType: 'route',
    actionParams: {
      page: CreateWorkSummaryPage,
      stateParams: {
        promoPathId: path?.id,
      },
    },
  };
}

export function getEditAction(path: Nullable<CandidatePathResource>): ActionDefinition {
  return {
    action: 'edit',
    targetType: 'route',
    actionParams: {
      page: EditWorkSummaryPage,
      stateParams: {
        promoPathId: path?.id,
      },
    },
  };
}

export function getReviewAction(): ActionDefinition {
  return {
    action: 'review',
    targetType: 'route',
    actionParams: { page: ReviewWorkSummaryPage },
  };
}

export function getDeleteAction(onDelete: () => void): ActionDefinition {
  return {
    action: 'delete',
    targetType: 'function',
    actionParams: { doAction: onDelete },
  };
}

export function getApproveAction(onApprove: () => void): ActionDefinition {
  return {
    action: 'approve',
    targetType: 'function',
    actionParams: { doAction: onApprove },
  };
}

export function getSubmitForReviewAction(onSubmitForReview: () => void): ActionDefinition {
  return {
    action: 'submit',
    targetType: 'function',
    actionParams: { doAction: onSubmitForReview },
  };
}

export function getRecallAction(onRecall: () => void): ActionDefinition {
  return {
    action: 'recall',
    targetType: 'function',
    actionParams: { doAction: onRecall },
  };
}

export function getReqPeerReviewAction(onRequestPeerReview: () => void): ActionDefinition {
  return {
    action: 'request peer review',
    targetType: 'function',
    actionParams: { doAction: onRequestPeerReview },
  };
}

export function getCandidateReturnAction(onReturnToCandidate: () => void): ActionDefinition {
  return {
    action: 'return to candidate',
    targetType: 'function',
    actionParams: { doAction: onReturnToCandidate },
  };
}

export function getStatusFilterOptions(): PropertyFilterOption[] {
  return Object.keys(WorkSummaryStatus).map((status) => ({
    propertyKey: 'status',
    value: status,
    label: getLabelFromEnum(status),
  }));
}

export function getCategoryFilterOptions(categories: CategoryItem[]): PropertyFilterOption[] {
  if (!categories.length) {
    return [];
  }
  return [
    ...categories.map((category) => ({
      propertyKey: 'categories',
      value: category.label,
      label: category.label,
    })),
    {
      propertyKey: 'categories',
      value: 'Uncategorized',
    },
  ].sort((a, b) => (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1));
}

export const statusPropertyFilter: PropertyFilterProperty<WorkSummaryStatus> = {
  key: 'status',
  propertyLabel: 'Status',
  groupValuesLabel: getGroupValueLabel('Status'),
  operators: ['=', '!='].map(
    (op) =>
      ({
        operator: op,
        format: (value) => getLabelFromEnum(value),
      }) as PropertyFilterOperatorExtended<WorkSummaryStatus>
  ),
  defaultOperator: '=',
};

export const categoryPropertyFilter: PropertyFilterProperty<string> = {
  key: 'categories',
  propertyLabel: 'Category',
  groupValuesLabel: getGroupValueLabel('Category'),
  operators: [
    {
      operator: '=',
      match: (categories: CategoryItem[], categoryName) =>
        (!categories?.length && categoryName.toLowerCase() === 'uncategorized') ||
        categories.some((opt) => opt.label.toLowerCase() === categoryName.toLowerCase()),
    },
    {
      operator: '!=',
      match: (categories: CategoryItem[], categoryName) =>
        !(!categories?.length && categoryName.toLowerCase() === 'uncategorized') ||
        !categories.some((opt) => opt.label.toLowerCase() === categoryName.toLowerCase()),
    },
  ] as PropertyFilterOperatorExtended<string>[],
  defaultOperator: '=',
};

function useCounterText(items?: readonly WorkSummaryItem[]): string {
  const totalItems = items?.length ?? 0;
  // Per AWS Style Guide always set to 0 if no items.
  if (!totalItems) {
    return '(0)';
  }

  return `(${totalItems})`;
}

/**
 * Table header component for a `WorkSummary` table.
 * Header text consists of the `tableKey` from {@link PromoTableDefinition}, and the total item count.
 * When all available summaries are being shown, the header will indicate the total `Approved` summaries.
 *
 * Returns a {@link https://refresh.polaris.a2z.com/components/header/?tabId=playground Polaris Header}
 */
const WorkSummaryHeader = <T extends WorkSummaryItem>({
  items,
  ...params
}: TableHeaderParams<PromoTableDefinition, T>): React.ReactElement => {
  return <TableHeader {...params} counter={useCounterText(items)} actionSet={ActionSet} />;
};

function getStatusType(status: WorkSummaryStatus): StatusIndicatorProps.Type {
  switch (status) {
    case WorkSummaryStatus.APPROVED:
      return 'success';
    case WorkSummaryStatus.REJECTED:
    case WorkSummaryStatus.DELETED:
      return 'error';
    default:
      return 'in-progress';
  }
}

export const WorkSummaryStatusInd = ({ status }: { status: WorkSummaryStatus }) => (
  <Popover
    dismissAriaLabel="close"
    dismissButton
    header="Progress"
    position="right"
    triggerType="text"
    size="medium"
    content={<WorkSummaryProgress status={status} variant="default" />}
  >
    <StatusIndicator type={getStatusType(status)}>{getLabelFromEnum(status)}</StatusIndicator>
  </Popover>
);

export const getHeaderComponent = () => WorkSummaryHeader;
