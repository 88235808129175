import Badge from '@amzn/awsui-components-react/polaris/badge';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Popover from '@amzn/awsui-components-react/polaris/popover';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { CategoryItem, OptionalString } from '@/models';

interface WorkSummaryIdParams {
  id: OptionalString;
}

export const WorkSummaryId = ({ id }: WorkSummaryIdParams): React.ReactElement => (
  <Box variant="span">
    {!!id && (
      <Box margin={{ right: 'xxs' }} display="inline-block">
        <Popover
          size="small"
          position="top"
          triggerType="custom"
          dismissButton={false}
          content={<StatusIndicator type="success">Work summary ID copied</StatusIndicator>}
        >
          <Button variant="inline-icon" iconName="copy" onClick={() => navigator.clipboard.writeText(id)} />
        </Popover>
      </Box>
    )}
    {id ? id.slice(Math.max(0, id.length - 8)) : '-'}
  </Box>
);

interface CategorySelectionParams {
  categories?: CategoryItem[];
}

export const CategorySelection = ({ categories }: CategorySelectionParams): React.ReactElement =>
  categories?.length ? (
    <SpaceBetween direction="horizontal" size="xxs">
      {categories.map((category) => (
        <Badge color="blue" key={`${category.id}.label`}>
          {category.label}
        </Badge>
      ))}
    </SpaceBetween>
  ) : (
    <StatusIndicator type="info">Uncategorized</StatusIndicator>
  );

interface LeadershipPrincipleSelectionParams {
  lpItems: string[] | undefined;
}

export const LeadershipPrincipleSelection = ({ lpItems }: LeadershipPrincipleSelectionParams): React.ReactElement =>
  lpItems?.length ? (
    <SpaceBetween direction="horizontal" size="xxs">
      {lpItems.map((lp) => (
        <Badge color="blue" key={`${lp}.label`}>
          {lp}
        </Badge>
      ))}
    </SpaceBetween>
  ) : (
    <Box>-</Box>
  );
