
import { useRef, useEffect, useMemo, useState, useCallback } from 'react';
import { Nullable, CandidateStatusItem, PromoUserProfile, ManagerPathItem, DirectReportRecord } from '@/models';
import { getCreateAction, getDeleteAction, getEditAction, getHeaderComponent } from './common-components';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Button from '@amzn/awsui-components-react/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import Select from '@amzn/awsui-components-react/polaris/select';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import { usePromoReadinessProfile, usePromoReadinessActions } from '@/api/promo-readiness';
import { useCandidatePromoPathList } from '@/api/candidate-promo-path';
import * as $ from 'jquery';


interface PromoReadinessTableParams {
  user: Nullable<PromoUserProfile>;
  directReports: DirectReportRecord[];
  isParentLoading: boolean;
  manager: Nullable<PromoUserProfile>;
}


interface OptionDefinition {
  label: string;
  value: string;
}

interface ScoreBreakdown {
  [key: string]: number;
}

const PromoReadinessTable = ({ user, directReports, isParentLoading, manager }: PromoReadinessTableParams): JSX.Element => {
  const [selectedTechPillars, setSelectedTechPillars] = useState<OptionDefinition[]>([]);
  const [selectedCerts, setSelectedCerts] = useState<OptionDefinition[]>([]);
  const [selectedTfcStatus, setselectedTfcStatus] = useState<OptionDefinition[]>([]);
  const [selectedCustomerStories, setselectedCustomerStories] = useState<OptionDefinition[]>([]);
  const [selectedPerformanceStatus, setselectedPerformanceStatus] = useState<OptionDefinition[]>([]);
  const [selectedOtherContributionStatus, setselectedOtherContributionStatus] = useState<OptionDefinition[]>([]);
  const [inputtedTAMs, setInputtedTAMs] = useState<string[]>([]);
  const [AwsTenure, setAwsTenure] = useState<number | null>(null);
  const [TimeWithRoleTenure, setTimeWithRoleTenure] = useState<number | null>(null);
  const [TimeWithLevel, setTimeWithLevel] = useState<number | null>(null);



  const [totalScore, setTotalScore] = useState(0);
  const [scoreBreakdown, setScoreBreakdown] = useState<ScoreBreakdown>({});



  const [selectedESM, setSelectedESM] = useState<OptionDefinition | null>(null);
  const [selectedTAM, setSelectedTAM] = useState<OptionDefinition | null>(null);
  const { isCandidatePathsLoading, candidateToPath } = useCandidatePromoPathList(manager?.alias);

  const [certificationResponse, setCertificationResponse] = useState<string[]>([]);
   const latestTAMRef = useRef<OptionDefinition | null>(selectedTAM);
  
   const [verificationResults, setVerificationResults] = useState<string[]>([]);
   const [newTAMName, setNewTAMName] = useState<string>('');
  
   const [feedbackProviders, setFeedbackProviders] = useState<string[]>([]);
   const [feedbackVerificationResults, setFeedbackVerificationResults] = useState<string[]>([]);
  
   const { getPromoReadinessRecord, promoReadiness, fetchLatest } = usePromoReadinessProfile('gfong');

   useEffect(() => {
     const fetchData = async () => {
       const data = await getPromoReadinessRecord('gfong');
       console.log('Fetched data:', data);
     };
 
     fetchData();
   }, [getPromoReadinessRecord]);
   console.log("PromoReadiness Get Query", promoReadiness)

 const [alias, setAlias] = useState('');
 const { createPromoReadinessEntry, updatePromoReadinessEntry, isProfileMutating } = usePromoReadinessActions(alias);
 const handleUpdateEntry = async () => {
   const updatedFields = {
     alias: 'gfong',
     techPillar: ['here']
   };

   try {
     const updatedEntry = await updatePromoReadinessEntry(updatedFields);
     console.log('Updated entry:', updatedEntry);
   } catch (error) {
     console.error('Error updating entry:', error);
   }
 };
 // handleUpdateEntry()
 
   const certificateOptions: ReadonlyArray<OptionDefinition> = [
     { label: 'AWS Certified Cloud Practitioner', value: 'AWS Certified Cloud Practitioner' },
     { label: 'AWS Certified Solutions Architect - Professional', value: 'AWS Certified Solutions Architect - Professional' },
     { label: 'AWS Certified DevOps Engineer - Professional', value: 'AWS Certified DevOps Engineer - Professional' },
     { label: 'AWS Certified Security - Specialty', value: 'AWS Certified Security - Specialty' },
     { label: 'AWS Certified Machine Learning - Specialty', value: 'AWS Certified Machine Learning - Specialty' },
     { label: 'AWS Certified Advanced Networking - Specialty', value: 'AWS Certified Advanced Networking - Specialty' },
     { label: 'AWS Certified SysOps Administrator - Associate', value: 'AWS Certified SysOps Administrator - Associate' },
     { label: 'AWS Certified Solutions Architect - Associate', value: 'Solution Architect Associate' },
     { label: 'AWS Certified Developer - Associate', value: 'AWS Certified Developer - Associate' },
     { label: 'AWS Certified Data Engineer - Associate', value: 'AWS Certified Data Engineer - Associate' },
     { label: 'AWS Certified Machine Learning Engineer - Associate', value: 'AWS Certified Machine Learning Engineer - Associate' },
   ];
  

   const calculateScore = () => {
    let score = 0;
    const breakdown: ScoreBreakdown = {};

    // Certifications
    let certificationScore = 0;

    // Count types of certifications
    const professionalCerts = selectedCerts.filter(cert => cert.value.includes('Professional'));
    const specialtyCerts = selectedCerts.filter(cert => cert.value.includes('Specialty'));
    const associateCerts = selectedCerts.filter(cert => cert.value.includes('Associate'));

    // Calculate the score for Professional and Specialty
    const totalProfessionalAndSpecialty = professionalCerts.length + specialtyCerts.length;

    if (professionalCerts.length > 0 && specialtyCerts.length > 0) {
        // Both Professional and Specialty present
        certificationScore = 50;
        certificationScore += (totalProfessionalAndSpecialty - 2) * 10; // Subtract 2 for the first Professional and Specialty
    } else if (totalProfessionalAndSpecialty > 0) {
        // Either Professional or Specialty present
        certificationScore = 25;
        certificationScore += (totalProfessionalAndSpecialty - 1) * 10; // Subtract 1 for the first cert (Professional or Specialty)
    }
    else if (totalProfessionalAndSpecialty > 0) {certificationScore = 0;}

    // Add score for Associate certifications
    certificationScore += associateCerts.length * 4;

    // Add to total score and breakdown
    score += certificationScore;
    breakdown['Certifications'] = certificationScore;


    // Customer Stories
    let customerStoriesScore = 0;
    const selectedCustomerStoryValues = selectedCustomerStories.map(story => story.value);

    if (selectedCustomerStoryValues.includes('1 story that align with Next Level Role guidelines')) {
      customerStoriesScore = 75;
    } else if (selectedCustomerStoryValues.includes('2 stories that align with Next Level Role guidelines')) {
      customerStoriesScore = 150;
    } else if (selectedCustomerStoryValues.includes('3 stories that align with Next Level Role guidelines')) {
      customerStoriesScore = 225;
    } else if (selectedCustomerStoryValues.includes('2 or more stories exemplifying more Pillar demonstration') ) {
      customerStoriesScore = 300;
    }   

    score += customerStoriesScore;
    breakdown['Customer Stories/Pillar Areas'] = customerStoriesScore;

     
       // TFC Contributions
       const tfcStatus = selectedTfcStatus.length > 0 ? selectedTfcStatus[0].value : 'Bronze during Review';
       let tfcScore = 0;
       if (tfcStatus === 'Silver during Review') tfcScore = 50;
       else if (tfcStatus === 'Gold during Review') tfcScore = 100;
       else if (tfcStatus === 'Gold QoQ') tfcScore = 150;
       else if (tfcStatus === 'Gold Year Round') tfcScore = 200;
       score += tfcScore;
       breakdown['TFC Contributions'] = tfcScore;
   

    // AWS Tenure
    if (AwsTenure !== null) {
      let tenureScore = 0;
      if (AwsTenure < 2) tenureScore = 6.25;
      else if (AwsTenure === 2) tenureScore = 12.5;
      else if (AwsTenure === 3) tenureScore = 12.5;
      else if (AwsTenure === 4) tenureScore = 18.75;
      else if (AwsTenure >= 5) tenureScore = 25;
      score += tenureScore;
      breakdown['AWS Tenure'] = tenureScore;
    }

    // Time in Level
    if (TimeWithLevel !== null) {
      let levelScore = 0;
      if (TimeWithLevel < 2) levelScore = 25;
      else if (TimeWithLevel === 2) levelScore = 50;
      else if (TimeWithLevel === 3) levelScore = 50;
      else if (TimeWithLevel === 4) levelScore = 75;
      else if (TimeWithLevel >= 5) levelScore = 100;
      score += levelScore;
      breakdown['Time in Level'] = levelScore;
    }

    // Time in Role
    if (TimeWithRoleTenure !== null) {
      let roleScore = 0;
      if (TimeWithRoleTenure < 2) roleScore = 6.25;
      else if (TimeWithRoleTenure === 2) roleScore = 12.5;
      else if (TimeWithRoleTenure === 3) roleScore = 12.5;
      else if (TimeWithRoleTenure === 4) roleScore = 18.75;
      else if (TimeWithRoleTenure >= 5) roleScore = 25;
      score += roleScore;
      breakdown['Time in Role'] = roleScore;
    }

    // Performance Bar Rating
    const performanceRating = selectedPerformanceStatus.length > 0 ? selectedPerformanceStatus[0].value : 'Under the performance Bar in any of the preceding 2 years';
    let performanceScore = 0;
    if (performanceRating === 'Meet the performance Bar in the preceding 2 years showing YoY improvement') performanceScore = 50;
    else if (performanceRating === 'Exceed the Bar in both of the preceding 2 years') performanceScore = 75;
    else if (performanceRating === 'Role Model in both of the preceding 2 years') performanceScore = 100;
    score += performanceScore;
    breakdown['Performance Bar Rating'] = performanceScore;

      // Feedback providers
      let feedbackProvidersScore = 0;
      const numFeedbackProviders = inputtedTAMs.length;

      if (numFeedbackProviders <= 5) {
        feedbackProvidersScore = 0;
      } else if (numFeedbackProviders === 6) {
        feedbackProvidersScore = 85;
      } else if (numFeedbackProviders > 6) {
        feedbackProvidersScore = 100;
      }

      score += feedbackProvidersScore;
      breakdown['Feedback Providers'] = feedbackProvidersScore;


    // Other Contribution Stories (Technical/Business)
    let otherContributionsScore = 0;
    const selectedOtherContributionValues = selectedOtherContributionStatus.map(option => option.value);

    if (selectedOtherContributionValues.includes("Doesn't meet Scope and Impact of Next level Role - Participates in/Leads Local initiatives")) {
      otherContributionsScore = 50;
    } else if (selectedOtherContributionValues.includes('Meets Scope and Impact of Next level Role - Participates in Global Initiatives')) {
      otherContributionsScore = 150;
    } else if (selectedOtherContributionValues.includes('Exceeds Scope and Impact of Next level Role - Leads Global Initiatives')) {
      otherContributionsScore = 200;
    }

    score += otherContributionsScore;
    breakdown['Other Contribution Stories'] = otherContributionsScore;

setTotalScore(score);
setScoreBreakdown(breakdown);
  };


  const getScoreLabel = () => {
    const performanceBarRatingScore = scoreBreakdown['Performance Bar Rating'] || 0;
    const feedbackProvidersScore = scoreBreakdown['Feedback Providers'] || 0;

    if (performanceBarRatingScore === 0 || feedbackProvidersScore === 0) {
      return 'Subpar';
    }

    if (totalScore < 600) {
      return 'Subpar';
    } else if (totalScore >= 600 && totalScore < 700) {
      return 'Marginal';
    } else if (totalScore >= 700 && totalScore < 775) {
      return 'Inconclusive';
    } else if (totalScore >= 775 && totalScore < 900) {
      return 'Satisfactory';
    } else if (totalScore >= 900 && totalScore <= 1150) {
      return 'Exceptional';
    }

    return '';
  };
   const fetchCertifications = useCallback(() => {
    if (!selectedTAM) return;
 
    const url = `https://mqgrmeiggk.execute-api.us-east-2.amazonaws.com/credly/badges?email=${selectedTAM.value}@amazon.com`;
    const maxRetries =3;
    let attempt = 0;
 
    const makeRequest = () => {
    $.ajax({
        url,
        method: 'GET',
        success: (data: any) => {
            try {
                const certNames = data.map((item: any) => item.name);
                const updatedCertOptions = certificateOptions.map(option => ({
                    ...option,
                    checked: certNames.includes(option.label)
                }));
                setSelectedCerts(updatedCertOptions.filter(option => option.checked));
                setCertificationResponse(certNames);
            } catch (error) {
                console.error("Failed to parse certifications data", error);
            }
        }
    });
  };
    makeRequest();
}, [selectedTAM, certificateOptions]);
 
 
  useEffect(() => {
    if (selectedTAM) {
      fetchCertifications();
    }
  }, [selectedTAM]);
 
 
  useEffect(() => {
    setInputtedTAMs([]);
    setVerificationResults([]);
  }, [selectedTAM]);

  useEffect(() => {
    calculateScore();
  }, [selectedTAM, selectedTechPillars, selectedCerts, selectedTfcStatus, selectedPerformanceStatus, selectedOtherContributionStatus, selectedCustomerStories, inputtedTAMs, AwsTenure, TimeWithRoleTenure, TimeWithLevel]);

  const techPillarOptions: ReadonlyArray<OptionDefinition> = [
    { label: 'Security', value: 'Security' },
    { label: 'Operational Excellence', value: 'Operational Excellence' },
    { label: 'Reliability', value: 'Reliability' },
    { label: 'Performance Efficiency', value: 'Performance Efficiency' },
  ];

  const CustomerStories: ReadonlyArray<OptionDefinition> = [
    { label: '2 or more stories exemplifying more Pillar demonstration', value: '2 or more stories exemplifying more Pillar demonstration' },
    { label: '3 stories that align with Next Level Role guidelines', value: '3 stories that align with Next Level Role guidelines' },
    { label: '2 stories that align with Next Level Role guidelines', value: '2 stories that align with Next Level Role guidelines' },
    { label: '1 story that align with Next Level Role guidelines', value: '1 story that align with Next Level Role guidelines' },
  ];


  const tfcPillarOptions: ReadonlyArray<OptionDefinition> = [
    { label: 'Gold Year Round', value: 'Gold Year Round'},
    { label: 'Gold QoQ', value: 'Gold QoQ'},
    { label: 'Gold during Review', value: 'Gold during Review' },
    { label: 'Silver during Review', value: 'Silver during Review'},
    { label: 'Bronze during Review', value: 'Bronze during Review'},
   
  ];

  const performanceRatingOptions: ReadonlyArray<OptionDefinition> = [
    { label: 'Role Model in both of the preceding 2 years', value: 'Role Model in both of the preceding 2 years'},
    { label: 'Exceed the Bar in both of the preceding 2 years', value:'Exceed the Bar in both of the preceding 2 years'},
    { label: 'Meet the performance Bar in the preceding 2 years showing YoY improvement', value: 'Meet the performance Bar in the preceding 2 years showing YoY improvement'},
    { label: 'Under the performance Bar in any of the preceding 2 years', value: 'Under the performance Bar in any of the preceding 2 years'}
  ];

  const OtherContributionsOptions: ReadonlyArray<OptionDefinition> = [
    { label: 'Exceeds Scope and Impact of Next level Role - Leads Global Initiatives', value: 'Exceeds Scope and Impact of Next level Role - Leads Global Initiatives'},
    { label: 'Meets Scope and Impact of Next level Role - Participates in Global Initiatives', value:'Meets Scope and Impact of Next level Role - Participates in Global Initiatives'},
    { label: "Doesn't meet Scope and Impact of Next level Role - Participates in/Leads Local initiatives", value: "Doesn't meet Scope and Impact of Next level Role - Participates in/Leads Local initiatives"}
  ];


  const timeInRoleForTAM = useCallback((tamAlias: string) => {
    const selectedTAMItem = directReports.find((item) => item.alias === tamAlias);
    if (!selectedTAMItem || !selectedTAMItem.hireDate) return null;


  const hireDate = new Date(selectedTAMItem.hireDate);
     const currentDate = new Date();
     const timeDiff = Math.abs(currentDate.getTime() - hireDate.getTime());
     const yearsInRole = Math.floor(timeDiff / (1000 * 3600 * 24 * 365));
     return yearsInRole;
 }, [directReports]);
  
 const handleAddTAM = () => {
   if (newTAMName && !inputtedTAMs.includes(newTAMName)) {
       setInputtedTAMs([...inputtedTAMs, newTAMName]);
       setNewTAMName('');
   }
 };
  
 const handleRemoveTAM = (index: number) => {
   setInputtedTAMs(prevTAMs => prevTAMs.filter((_, i) => i !== index));
 };
  
 const handleVerifyTAMs = () => {
   const results = inputtedTAMs.map((tamAlias) => {
       const years = timeInRoleForTAM(tamAlias);
       if (years !== null) {
           return `${tamAlias}: ${years >= 2 ? 'Met' : 'Not Qualified'} (${years} years)`;
       }
       return `${tamAlias}: No data available`;
   });
   setVerificationResults(results);
 };
  
 const handleAddFeedbackProvider = () => {
   if (newTAMName && !feedbackProviders.includes(newTAMName)) {
     setFeedbackProviders([...feedbackProviders, newTAMName]);
     setNewTAMName('');
   }
 };
  
 const handleVerifyFeedbackProviders = () => {
   const verificationResults = feedbackProviders.map(provider => {
     const years = timeInRoleForTAM(provider);
     if (years !== null) {
       return `${provider}: ${years >= 2 ? 'Met' : 'Not Qualified'} (${years} years)`;
     }
     return `${provider}: No data available`;
   });
   setFeedbackVerificationResults(verificationResults);
 };

 const handleTechPillarMultiSelectChange = useCallback(
  (event: any) => {
    const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
    setSelectedTechPillars(selectedOptions);
  },
  [setSelectedTechPillars]
);

const handleCustomerStories = useCallback(
  (event: any) => {
    const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
    setselectedCustomerStories(selectedOptions);
  },
  [setselectedCustomerStories]
)



  const handleCertMultiSelectChange = useCallback(
    (event: any) => {
      const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
      setSelectedCerts(selectedOptions);

    },
    [setSelectedCerts]
  );

  const handleTfcStatusMultiSelectChange = useCallback(
    (event: any) => {
      const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
      setselectedTfcStatus(selectedOptions);
    },
    [setselectedTfcStatus]
  );

  const handlePerformanceStatusMultiSelectChange = useCallback(
    (event: any) => {
      const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
      setselectedPerformanceStatus(selectedOptions);
    },
    [setselectedPerformanceStatus]
  );

  const handleOtherContributionsStatusMultiSelectChange = useCallback(
    (event: any) => {
      const selectedOptions = event.detail.selectedOptions as OptionDefinition[];
      setselectedOtherContributionStatus(selectedOptions);
    },
    [setselectedOtherContributionStatus]
  );

  const items: CandidateStatusItem[] = useMemo(
    () =>
      directReports
        .map((directReport) => {
          const candidatePath = candidateToPath.get(directReport.alias);
          return {
            ...directReport,
            ...candidatePath,
            id: directReport.alias,
            name: directReport.name || `${directReport.firstName} ${directReport.lastName}` || '',
            pathName: candidatePath?.name,
            pathId: candidatePath?.id,
          };
        })
        .sort((a, b) => -a.jobLevel + b.jobLevel || (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    [directReports, candidateToPath]
  );

  const esmOptions: ReadonlyArray<OptionDefinition> = useMemo(
    () => [{ label: `${user?.alias}`, value: `${user?.alias}` }],
    [user?.alias]
  );

  const tamOptionsMap: { [key: string]: OptionDefinition[] } = useMemo(
    () => ({
      [user?.alias || '']: items.map((item) => ({
        label: `${item.alias}`,
        value: `${item.alias}`,
      })),
    }),
    [user?.alias, items]
  );

  const handleESMChange = useCallback(
    (event: any) => {
      const selectedOption = event.detail.selectedOption as OptionDefinition;
      setSelectedESM(selectedOption);
      setSelectedTAM(null);
    },
    [setSelectedESM, setSelectedTAM]
  );

  const handleTAMChange = useCallback(
    (event: any) => {
      const selectedOption = event.detail.selectedOption as OptionDefinition;
      setSelectedTAM(selectedOption);
    },[]);


  const tamOptions = selectedESM ? tamOptionsMap[selectedESM.value] || [] : [];

  const timeInRole = useCallback(() => {
    if (!selectedTAM) {
      return null;
    }

    const selectedTAMAlias = selectedTAM.value;
    const selectedTAMItem = items.find((item) => item.alias === selectedTAMAlias);

    if (!selectedTAMItem || !selectedTAMItem.hireDate) {
      return null;
    }

    const { hireDate } = selectedTAMItem;
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - new Date(hireDate).getTime());
    const daysInRole = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const years = Math.floor(daysInRole / 365);
    const remainingDays = daysInRole % 365;

    if (years === 0) {
      return `${remainingDays} days`;
    } else if (remainingDays === 0) {
      return `${years} years`;
    } else {
      return `${years} years, ${remainingDays} days`;
    }
  }, [selectedTAM, items]);


  const awsyears =  parseInt(timeInRole()?.match(/(\d+)\s*years?/)?.[1] || '0');

  useEffect(() => {
    if (selectedTAM) {
      setAwsTenure(awsyears);
      setTimeWithRoleTenure(awsyears);
      setTimeWithLevel(awsyears);
      const initialTAMs = ['hbrito', 'jagathe', 'kenren', 'gfong', 'vpullela','hbrito', 'aliraze'];
      setInputtedTAMs(initialTAMs);
      
    }
  }, [selectedTAM]);

  const getTimeInRoleColor = (TAM_level: number, timeInRole: number) => {
    if (TAM_level === 6) {
      if (timeInRole >= 3) return 'forestgreen';
      if (timeInRole == 2 ) return 'orange';
      return 'red';
    } else if (TAM_level === 5) {
      if (timeInRole > 2) return 'forestgreen';
      if (timeInRole >= 1 ) return 'orange';
      return 'red';
    } else if (TAM_level === 4) {
      if (timeInRole > 2) return 'forestgreen';
      if (timeInRole >= 1) return 'orange';
      return 'red';
    }
    //return 'red'; // default color if TAM_level is different or undefined
  };

  const getFeedbackColor = (TAM_level: number) => {
  
    const requiredCount = inputtedTAMs.length ;
 
    if (TAM_level === 6) {
 
      const allMet = requiredCount >= 6 &&
      verificationResults.every(result => result.includes('Met'));
      return allMet ? 'forestgreen' : 'red';
 
    } else if (TAM_level === 5) {
         const allMet = requiredCount >= 5 &&
         verificationResults.some(result => result.includes('Met'));
      return allMet ? 'forestgreen' : 'red';
    } else if (TAM_level === 4) {
       const allMet = requiredCount >= 4 &&
       verificationResults.every(result => result.includes('Met'));
      return allMet ? 'forestgreen' : 'red';
    }
    return 'grey';
  };
 


  const tamIndicatorByLevel = {
    4: {
      "certifcation": {
        "Associate": 1,
        "Exceeds": {"Associate": 0,
                    "Professional": 0,
                    "Specialty": 0}
      },
      "pillar": {
        "Security": 200,
        "Operational Excellence": 200,
        "Reliability": 200,
        "Performance Efficiency": 200
      },
      "timeInRole": 365
    },
    5: {
      "certifcation": {
        "Professional": 1,
        "Exceeds": {
          "Associate": 0,
          "Professional": 1,
          "Specialty": 0
        }
      },
      "pillar": {
        "Security": 200,
        "Operational Excellence": 200,
        "Reliability": 200,
        "Performance Efficiency": 200
      },
      "timeInRole": 365
    },
    6: {
      "certifcation": {
        "Professional": 1,
        "Exceeds": {
          "Professional": 1,
          "Specialty": 0}
      },
      "pillar": {
        "Security": 200,
        "Operational Excellence": 200,
        "Reliability": 200,
        "Performance Efficiency": 200
      },
      "timeInRole": 365
    }
  };
  const calculateCertCriteria = useCallback(() => {
    if (!selectedTAM) {
      return null;
    }

    const selectedTAMItem = items.find((item) => item.jobLevel);
    console.log("selectedTAMItem", selectedTAMItem)

    if (!selectedTAMItem || !selectedTAMItem.hireDate) {
      console.log(selectedTAMItem)
      return null;
    }
    const numAssociateCerts = selectedCerts.filter(cert => cert.label.includes("Associate")).length;
    const numProfessionalCerts = selectedCerts.filter(cert => cert.label.includes("Professional")).length;
    const numSpecialtyCerts = selectedCerts.filter(cert => cert.label.includes("Specialty")).length;

    const meets = numAssociateCerts >= tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Associate ||
    numProfessionalCerts >= tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Professional ||
    numSpecialtyCerts >= tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Specialty

    const exceeds = numAssociateCerts > tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Exceeds.Associate ||
    numProfessionalCerts > tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Exceeds.Professional ||
    numSpecialtyCerts > tamIndicatorByLevel[selectedTAMItem.jobLevel]?.certifcation?.Exceeds.Specialty
    console.log("Meets, Exceeds", meets, exceeds)

    if (meets && exceeds) {
      return "forestgreen";
    } else if (meets) {
      return "orange";
    } else {
      return "red";
    }

  }, [selectedTAM, items, tamIndicatorByLevel]);

  const selectedTAMDetails = useMemo(() => {
    return items.find(item => item.alias ===selectedTAM?.value) || null;
  }, [selectedTAM, items]);


    const TAM_level = Number(selectedTAMDetails?.jobLevel);
    const TAM_alias = selectedTAMDetails?.alias
    const getCertColor = calculateCertCriteria()

  const getPillarStatusColor = (TAM_level: number, selectedCount: number) => {

  
      if (TAM_level === 6) {
        if (selectedCount === 4) return 'forestgreen';
        if (selectedCount === 3) return 'orange';
        return 'grey';
      } else if (TAM_level === 5) {
        if (selectedCount >= 3) return 'forestgreen';
        if (selectedCount === 2) return 'orange';
        return 'grey';
      } else if (TAM_level === 4) {
        if (selectedCount >= 3) return 'forestgreen';
        if (selectedCount === 2) return 'orange';
        return 'grey';
      }
    };

  const getCustomerStoriesColor = (TAM_level: number) => {

    let selected = selectedCustomerStories
      const includes1story = selected.some(item => item.label.includes("1 story that align with Next Level Role guidelines"));
      const includes2story = selected.some(item => item.label.includes("2 stories that align with Next Level Role guidelines"));
      const includes3story = selected.some(item => item.label.includes("3 stories that align with Next Level Role guidelines"));
      const includesMorestory = selected.some(item => item.label.includes("2 or more stories exemplifying more Pillar demonstration"));
      if (TAM_level === 6) {
        if (includesMorestory) return 'forestgreen';
        if (includes3story) return 'limegreen';
        if (includes2story) return 'orange';
        if (includes1story) return 'yellow';
        return 'grey';
      } else if (TAM_level === 5) {
        if (includesMorestory) return 'forestgreen';
        if (includes3story) return 'limegreen';
        if (includes2story) return 'orange';
        if (includes1story) return 'yellow';
        return 'grey';
      } else if (TAM_level === 4) {
        if (includesMorestory) return 'forestgreen';
        if (includes3story) return 'limegreen';
        if (includes2story) return 'orange';
        if (includes1story) return 'yellow';
        return 'grey';
      }
  };

    const getTfcStatusColor = (TAM_level: number) => {
      let selected = selectedTfcStatus

      const includesGold = selected.some(item => item.label.includes("Gold during Review"));
      const includesSilver = selected.some(item => item.label.includes("Silver during Review"));
      const includesBronze = selected.some(item => item.label.includes("Bronze during Review"));
      const includesGoldQoQ = selected.some(item => item.label.includes("Gold QoQ"));
      const includesGoldYearRound = selected.some(item => item.label.includes("Gold Year Round"));
      

      if (TAM_level === 6) {
        if (includesGoldYearRound) return 'forestgreen';
        if (includesGoldQoQ) return 'limegreen';
        if (includesGold) return 'orange';
        if (includesSilver) return 'yellow';
        if (includesBronze) return 'red';
        return 'grey';
      } else if (TAM_level === 5) {
        if (includesGoldYearRound) return 'forestgreen';
        if (includesGoldQoQ) return 'limegreen';
        if (includesGold) return 'orange';
        if (includesSilver) return 'yellow';
        if (includesBronze) return 'red';
        return 'grey'
      } else if (TAM_level === 4) {
        if (includesGoldYearRound) return 'forestgreen';
        if (includesGoldQoQ) return 'limegreen';
        if (includesGold) return 'orange';
        if (includesSilver) return 'yellow';
        if (includesBronze) return 'red';
        return 'grey'
      }
    };

    const getPerformanceBarColor = () => {
      let selected = selectedPerformanceStatus

      const includesExceededYoY = selected.some(item => item.label.includes("Role Model in both of the preceding 2 years"));
      const includesExceededLastReview = selected.some(item => item.label.includes("Exceed the Bar in both of the preceding 2 years"));
      const includesMeets = selected.some(item => item.label.includes("Meet the performance Bar in the preceding 2 years showing YoY improvement"));
      const includesNotMeets = selected.some(item => item.label.includes("Under the performance Bar in any of the preceding 2 years"));

      if (includesExceededYoY) return 'forestgreen';
      if (includesNotMeets) return 'red';
      if (includesExceededLastReview) return 'orange';
      if (includesMeets) return 'yellow';
      return 'grey';
     
    };


    const getOtherContributionsBarColor = () => {
      let selected = selectedOtherContributionStatus

      const includesExceededScope = selected.some(item => item.label.includes("Exceeds Scope and Impact of Next level Role - Leads Global Initiatives"));
      const includesMeetsScope = selected.some(item => item.label.includes("Meets Scope and Impact of Next level Role - Participates in Global Initiatives"));
      const includesNotMeetsScope = selected.some(item => item.label.includes("Doesn't meet Scope and Impact of Next level Role - Participates in/Leads Local initiatives"));

      if (includesExceededScope) return 'forestgreen';
      if (includesNotMeetsScope) return 'red';
      if (includesMeetsScope) return 'orange';
      return 'grey';
     
    };
  return(
      <div>
         <Select
          options={esmOptions}
          selectedOption={selectedESM}
          onChange={handleESMChange}
          placeholder="Select ESM"
        /> <br/>

         <Select
          options={tamOptions}
          selectedOption={selectedTAM}
          onChange={handleTAMChange}
          placeholder="Select TAM"
          disabled={!selectedESM}
        /> <br/>

      {selectedESM && selectedTAM && (
          <div>
  
  {/*  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <div >
                        <Button onClick={calculateScore}>Check Readiness Score</Button>
                     </div>
                 </div> */}
            <h3>Total Score: {totalScore} ({getScoreLabel()}) </h3>
            <h3>Score Breakdown (in points):</h3>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {Object.entries(scoreBreakdown).map(([category, score]) => (
                      <div key={category} style={{ marginRight: '10px', marginBottom: '20px' }}>
                        {category}: {score}
                      </div>
                    ))}
                  </div>
          </div>
        )}

        {
          selectedESM && selectedTAM && (
            <Cards

              cardDefinition={{
                header: item => item.name,
                sections: [
                  {
                    id: "description",
                    header: "Description",
                    content: item => item.description
                  },
                  {
                    id: "GoalIndicator",
                    header: "Goal Indicator :" ,
                    //content: item => item.GoalIndicator
                  },
                  // {
                  //   id: "UpdatePillar",
                  //   content: item => {

                  //     if (item.name === "Total Tech Pillar Areas") {
                    
                  //       const color = getPillarStatusColor(TAM_level, selectedTechPillars.length);
                  //       return (
                  //         <div>
                  //              <span style={{ backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', display: 'block',whiteSpace: 'nowrap', width:'auto', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '25px', marginTop: '-5px' }}>
                  //             Level {((TAM_level)+1)}</span>
                         
                  //         <Multiselect
                  //           selectedOptions={selectedTechPillars}
                  //           onChange={handleTechPillarMultiSelectChange}
                  //           options={techPillarOptions}
                  //           placeholder="Edit pillars"
                  //         />
                  //             </div>
                  //       );
                    
                  //     }
                  //   }
                  // },

         

                  {
                    id: "UpdatePillar",
                    content: item => {

                      if (item.name === "Total Tech Pillar Areas") {
                    
                        const color = getCustomerStoriesColor(TAM_level);
                        return (
                          <div>
                               <span style={{ backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', display: 'block',whiteSpace: 'nowrap', width:'auto', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '25px', marginTop: '-5px' }}>
                              Level {((TAM_level)+1)}</span>
                         
                          <Multiselect
                            selectedOptions={selectedCustomerStories}
                            onChange={handleCustomerStories}
                            options={CustomerStories.map(option => ({ ...option, disabled: selectedCustomerStories.length > 0 && !selectedCustomerStories.includes(option) }))} 
                            placeholder="Choose Story"
                          />
                              </div>
                        );
                    
                      }
                    }
                  },
                {
                  id: "TimeInRole",
                  content: item => {
                    if (item.name === "Time At Amazon") {

                     const years =  parseInt(timeInRole()?.match(/(\d+)\s*years?/)?.[1] || '0');
                     const color = getTimeInRoleColor(TAM_level, years );
                      return (

                        <div>
                          <span style={{ backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', display: 'block',whiteSpace: 'nowrap', width:'auto', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '30px', marginTop: '-25px' }}>
                         Level {((TAM_level)+1)}</span>
                         {item.Status}: {timeInRole()}

                        </div>

                      );
                    }

                    if (item.name === "Time in Current Level") {

                      const years =  parseInt(timeInRole()?.match(/(\d+)\s*years?/)?.[1] || '0');
                      const color = getTimeInRoleColor(TAM_level, years );
                       return (
                         <div>
                       <span style={{ backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '30px', marginTop: '-25px' }}>
                          Level {((TAM_level)+1)}</span>
                          {item.Status}: {timeInRole()}

                         </div>

                       );
                     }

                     if (item.name === "Time in Role") {

                      const years =  parseInt(timeInRole()?.match(/(\d+)\s*years?/)?.[1] || '0');
                      const color = getTimeInRoleColor(TAM_level, years );
                       return (
                         <div>
                       <span style={{ backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '30px', marginTop: '-25px' }}>
                          Level {((TAM_level)+1)}</span>
                          {item.Status}: {timeInRole()}
                        

                         </div>
                          

                       );
                     }

                  }
                },
                {
                  id: "UpdateCert",
                  content: item => {
                    if (item.name === "Maintain Technical Certifications") {
                      const certCriteria = calculateCertCriteria();
                      return (
                        <div>
                               <span style={{ backgroundColor: certCriteria as string, textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px',whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '25px', marginTop: '-50px' }}>
                               Level {((TAM_level)+1)}</span>
                          <Multiselect
                            selectedOptions={selectedCerts}
                            onChange={handleCertMultiSelectChange}
                            options={certificateOptions}
                            placeholder="Edit certificates"
                          />
                        </div>
                      );
                    }

                  }
                },
                {
                  id: "UpdateTfc",
                  content: item => {

                    if (item.name === "Technical Contributions (TFC)") {

                      const color = getTfcStatusColor(TAM_level)
                      return (
                        <div>
                          <span style={{ backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '25px', marginTop: '-65px' }}>
                            Level {((TAM_level)+1)}</span>

                            <Multiselect selectedOptions={selectedTfcStatus} 
                            onChange={handleTfcStatusMultiSelectChange} 
                            options={tfcPillarOptions.map(option => ({ ...option, disabled: selectedTfcStatus.length > 0 && !selectedTfcStatus.includes(option) }))} 
                            placeholder="Choose status" />
                            </div>
                      );

                    }
                  }
                },
                {
                  id: "UpdatePerformanceRating",
                  content: item => {

                    if (item.name === "Performance Bar Rating") {

                      const color = getPerformanceBarColor()
                      return (
                        <div>
                          <span style={{ backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)', fontSize:'17px', whiteSpace: 'nowrap', width:'auto', display: 'block', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '25px', marginTop: '-85px' }}>
                            Level {((TAM_level)+1)}</span>

                        <Multiselect
                          selectedOptions={selectedPerformanceStatus}
                          onChange={handlePerformanceStatusMultiSelectChange}
                          options={performanceRatingOptions.map(option => ({ ...option, disabled: selectedPerformanceStatus.length > 0 && !selectedPerformanceStatus.includes(option) }))} 
                          placeholder="Choose rating"
                        />
                            </div>
                      );

                    }
                  }
                },
                {
                  id: "Feedback Providers(FPs) > 24 months at Amazon, > 12 month in Role/Level, Min no of FPs, with 50% of FPs in Technical role",
                  content: item => {
                    if (item.name === "Feedback Providers(FPs) > 24 months at Amazon, > 12 month in Role/Level, Min no of FPs, with 50% of FPs in Technical role") {
                                    
                      return (        
                        <div>
                             <SpaceBetween direction="vertical" size="s">
                                <FormField label="Enter a Feedback Provider Name">
                                    <input
                                        type="text"
                                        value={newTAMName}
                                        onChange={(e) => setNewTAMName(e.target.value)}
                                    />
                                    <Button onClick={handleAddTAM}>Add</Button>
                                </FormField>
                                <div>
                                   <h4>Added Feedback Providers:</h4>
                                   <ul>
                                     {inputtedTAMs.map((tam, index) => (
                                       <li key={index}>{tam}
                                       <Button onClick={() => handleRemoveTAM(index)}> Remove</Button>
                                       </li>
                                     ))}
                                   </ul>
                                </div>
                                
                                <Button onClick={handleVerifyTAMs}>Verify</Button>
                              <div>
                                <div>
                                    
                                    <h4>Verification Results:</h4>
                                    {verificationResults.map((result, index) => (
                                        <li key={index}>{result}</li>
                                    ))}
                               
                                </div>
                                <div>
                                       
                                    <span style={{
                                      backgroundColor: getFeedbackColor(TAM_level),
                                      textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                      fontSize: '17px',
                                      display: 'block',
                                      whiteSpace: 'nowrap',
                                      width: 'auto',
                                      fontWeight: 'bold',
                                      padding: '2px 150px',
                                      textAlign: 'center',
                                      marginBottom: '20px',
                                      marginTop: '215px',
                                      position: 'absolute',
                                      top: '0',
                                      left: '5%'

                                    }}>
                                      Level {((TAM_level)+1)}
                                    </span>
                                  </div>
                              </div>
                            </SpaceBetween>
                     
                        </div>
                      );
                    }
                    
                  }
                }   ,
                {
                  id: "OtherContributions",
                  content: item => {

                    if (item.name === "Other Contributions (Non TFC)") {

                      const color = getOtherContributionsBarColor()
                      return (
                        <div>
                          <span style={{ backgroundColor: color,  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',  whiteSpace: 'nowrap', width:'auto',fontSize:'17px', display: 'block', fontWeight: 'bold', padding: '2px 150px', textAlign: 'center', marginBottom: '25px', marginTop: '-110px' }}>
                            Level {((TAM_level)+1)}</span>

                        <Multiselect
                          selectedOptions={selectedOtherContributionStatus}
                          onChange={handleOtherContributionsStatusMultiSelectChange}
                          options={OtherContributionsOptions.map(option => ({ ...option, disabled: selectedOtherContributionStatus.length > 0 && !selectedOtherContributionStatus.includes(option) }))} 
                          placeholder="Choose Contribution Stories"
                        />
                            </div>
                      );

                    }
                  }
                }
             ]
            }}
              items={[
                {
                  "name": "Maintain Technical Certifications",
                  "alt": "First",
                  "description": "Overall Certifications Achieved - Professional/Specialty/Associate/Foundational",
                  "GoalIndicator": "",
                  "Status": ""
                },
                {
                  "name": "Total Tech Pillar Areas",
                  "alt": "Second",
                  "description": "Total number of Tech Pillar Areas TAM has shown expertise at 200 Level",
                  "GoalIndicator": "Red",
                  "Status": "",
                  "TAMDetails": {TAM_level}
                },
                {
                  "name": "Technical Contributions (TFC)",
                  "alt": "Third",
                  "description": "Calculated total Points for last 1 year ",
                  "GoalIndicator": "Orange",
                  "Status": "Edit Status"
                },
                {
                  "name": "Time At Amazon",
                  "alt": "Fourth",
                  "description": "Time in Amazon",
                  "GoalIndicator": "Green",
                  "Status": "Duration"
                },
                {
                  "name": "Time in Current Level",
                  "alt": "Fifth",
                  "description": "Time in Current Level",
                  "GoalIndicator": "Red",
                  "Status": "Duration"
                },
                {
                  "name": "Time in Role",
                  "alt": "Sixth",
                  "description": "Time in Role",
                  "GoalIndicator": "Red",
                  "Status": "Duration"
                },
                {
                  "name": "Performance Bar Rating",
                  "alt": "Seventh",
                  "description": "Check to make sure individual has been exceeding the bar in last 2 years",
                  "GoalIndicator": "Orange",
                  "Status": "Edit Rating"
                },
                {
                  "name": "Feedback Providers(FPs) > 24 months at Amazon, > 12 month in Role/Level, Min no of FPs, with 50% of FPs in Technical role",
                  "alt": "Eigth",
                  "description": "Number of Feedback Providers & Review all Feedback providers to check that each have been at Amazon at least 2 years.",
                  "GoalIndicator": "Orange",
                  "Status": "Edit Feedback Provider"
                },
                {
                  "name": "Other Contributions (Non TFC)",
                  "alt": "Nineth",
                  "description": "Other contributions stories (tech and non-tech) from pathfinder or strategy pillar projects",
                  "GoalIndicator": "Orange",
                  "Status": "Edit Contributions"
                },

              ]}
              cardsPerRow={[
                {
                  "cards": 1
                },
                {
                  "minWidth": 500,
                  "cards": 3
                }
              ]}
              loadingText="Loading resources"
              header={ <div><h2>
              TAM Promotion Readiness Checker :  Current Job level of {TAM_alias} is {TAM_level}
            </h2></div>
            }
              empty={<div className="awsui-util-t-c">
              <div className="awsui-util-pt-xs awsui-util-mb-xs">
                <b>
                  No resources
                </b>
              </div>
              <p className="awsui-util-mb-s">
                No resources to display
              </p>
              <div className="awsui-util-mb-m">
                <Button>
                  Create resource
                </Button>
              </div>
            </div>}
            ></Cards>
              ) }        

     </div>
   );
 };

export default PromoReadinessTable;


