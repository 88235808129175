/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo } from 'react';
import { capitalize } from 'lodash';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GetWorkSummaryQuery,
  CreateWorkSummaryInput,
  UpdateWorkSummaryInput,
  CreateWorkSummaryMutation,
  UpdateWorkSummaryMutation,
  GetWorkSummaryQueryVariables,
  ListWorkSummariesByAliasQueryVariables,
  ListWorkSummariesByAliasQuery,
  ListWorkSummariesByAliasAdminQuery,
  CreateWorkSummaryFilesMutation,
  DeleteWorkSummaryFilesInput,
  DeleteWorkSummaryFilesMutation,
  WorkSummary as WorkSummaryModel,
  DeleteWorkSummaryInput,
  DeleteWorkSummaryMutation,
  WorkSummaryFiles,
  WorkSummaryStatus,
  Category,
  PeerReviewType,
  GetWorkSummaryFilesQuery,
  GetWorkSummaryFilesQueryVariables,
} from './API';
import {
  getWorkSummary as getWorkSummaryQuery,
  getWorkSummaryFiles as getWorkSummaryFilesQuery,
  listWorkSummariesByAlias,
  listWorkSummariesByAliasAdmin,
} from '@/graphql/queries';
import {
  createWorkSummary as createWorkSummaryMutation,
  updateWorkSummary as updateWorkSummaryMutation,
  deleteWorkSummary as deleteWorkSummaryMutation,
  createWorkSummaryFiles as createWorkSummaryFilesMutation,
  deleteWorkSummaryFiles as deleteWorkSummaryFilesMutation,
} from '@/graphql/mutations';
import { getFileRecordItemFromModel } from './file-record';
import {
  WorkSummaryResource,
  WorkSummaryItem,
  WorkSummaryAdminItem,
  FileRecordAttachment,
  WorkSummaryBase,
  OptionalString,
  ViewerType,
  Nullable,
} from '@/common/models';
import { useApiMutation, useApiQuery, getConcreteModels, AbstractModel } from '@/backend/api';
import { RESOURCES } from '@/common/constants';
import { getCategoryItemFromModel } from './category';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import { QueryKeys } from './queryKeys';
import { useNotifications } from '@/contexts';
import {
  UseAdminWorkSummariesResult,
  UseWorkSummariesResult,
  UseWorkSummaryActionsResult,
  UseWorkSummaryFilesResult,
  UseWorkSummaryResult,
  WorkSummaryActions,
} from './models';

const ITEM_TYPE = RESOURCES.WORKSUMMARY.resourceName;

type Model =
  | WorkSummaryModel
  | NonNullable<NonNullable<ListWorkSummariesByAliasQuery['listWorkSummariesByAlias']>['items'][number]>;

type AdminModel = NonNullable<
  NonNullable<ListWorkSummariesByAliasAdminQuery['listWorkSummariesByAlias']>['items'][number]
>;

type DocumentsRelation = NonNullable<WorkSummaryModel['documents']>['items'][number];

function isValidModel(model: Model | null | undefined): boolean {
  return !!model && !model._deleted && model.status !== WorkSummaryStatus.DELETED;
}

function isValidAdminModel(model: Model | null | undefined): boolean {
  return !!model;
}

function isValidRelatedModel(model: AbstractModel<DocumentsRelation>): boolean {
  return !!model && !model._deleted;
}

function getBaseFromModel(model: Model): WorkSummaryBase {
  const categories = (model.categories?.items ?? [])
    .filter((category) => !!category)
    .map((category) => getCategoryItemFromModel(category as Partial<Category>));
  return {
    categories,
    id: model.id,
    alias: model.alias,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    status: model.status || WorkSummaryStatus.DRAFT,
    statusReason: model.statusReason ?? undefined,
    title: model.title,
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    workSummaryType: capitalize(model.type.toString()),
    promoPathId: model.candidatePathId,
    submittedAt: getDateFromAPIValueUTC(model.submittedAt),
    coOwners: new Set(model.coOwners ?? []),
    version: model._version ?? 1,
  };
}

function getItemFromModel(model: Model): WorkSummaryItem {
  return {
    ...getBaseFromModel(model),
    commentCount: model.comments?.items?.length ?? 0,
  };
}

function getAdminItemFromModel(model: AdminModel): WorkSummaryAdminItem {
  const reviewerAlias = model.peerReviews?.items[0]?.reviewerAlias ?? '';
  const managerAlias = model.candidatePath?.manager ?? '';
  const candidatePromoPathVersion = model.candidatePath?._version ?? 1;

  return {
    ...getBaseFromModel(model),
    reviewerAlias,
    managerAlias,
    candidatePromoPathVersion,
  };
}

function parseDocuments(model: WorkSummaryModel): FileRecordAttachment[] {
  const documents = getConcreteModels<NonNullable<DocumentsRelation>>(model.documents?.items, isValidRelatedModel);
  return (documents ?? []).map((doc: NonNullable<WorkSummaryFiles>) => ({
    ...getFileRecordItemFromModel(doc.fileRecord),
    fileAttachmentId: doc.id,
  }));
}

function getResourceFromModel(model: WorkSummaryModel): WorkSummaryResource {
  const categories = (model.categories?.items ?? [])
    .filter((category) => !!category)
    .map((category) => getCategoryItemFromModel(category as Partial<Category>));
  return {
    ...getBaseFromModel(model),
    categories,
    content: model.content,
    contentVersion: model.contentVersion ?? 1,
    documents: parseDocuments(model),
    leadershipPrinciples: model.leadershipPrinciples ?? [],
    peerReviewers: (model.peerReviews?.items ?? [])
      .filter((review): review is NonNullable<typeof review> => !!review && !review._deleted)
      .map((review) => ({
        id: review.id,
        alias: review.reviewerAlias,
        name: review.reviewer.name ?? '',
        version: review._version,
      })),
  };
}

interface UseQueryParams {
  id: OptionalString;
  viewer?: ViewerType;
  notifyOnError?: boolean;
}

interface UseWorkSummaryQueryOptionsResult {
  build: (params: UseQueryParams) => UseQueryOptions<WorkSummaryResource | null>;
}

function useWorkSummaryQueryOptions(): UseWorkSummaryQueryOptionsResult {
  const { getItem } = useApiQuery();

  const buildOptions = useCallback(
    ({ id, viewer, notifyOnError = false }: UseQueryParams): UseQueryOptions<WorkSummaryResource | null> => ({
      meta: { notifyOnError, errorMessage: `Error fetching work summary ${getCompactId(id)}` },
      queryKey: QueryKeys.workSummary.id(id).all,
      queryFn: async () => {
        if (!id) return null;
        const reviewType = viewer === ViewerType.MANAGER ? PeerReviewType.MANAGER : PeerReviewType.CANDIDATE;
        const data = await getItem<GetWorkSummaryQueryVariables, GetWorkSummaryQuery>({
          query: getWorkSummaryQuery,
          input: { id, peerReviewFilter: { reviewType: { eq: reviewType } } },
        });
        const models = getConcreteModels<Model>(data?.getWorkSummary, isValidModel);
        return models?.length ? getResourceFromModel(models[0] as WorkSummaryModel) : null;
      },
    }),
    [getItem]
  );

  return { build: buildOptions };
}

interface UseWorkSummaryLoaderResult {
  getWorkSummary: (id: OptionalString) => Promise<Nullable<WorkSummaryResource>>;
  clearCache: (id: OptionalString) => void;
}

export function useWorkSummaryLoader(viewer?: ViewerType): UseWorkSummaryLoaderResult {
  const queryClient = useQueryClient();
  const queryOptions = useWorkSummaryQueryOptions();

  const getWorkSummary = useCallback(
    async (id: OptionalString) => queryClient.fetchQuery({ ...queryOptions.build({ id, viewer }) }),
    [queryOptions, queryClient, viewer]
  );

  const clearCache = useCallback(
    (id: OptionalString) => queryClient.removeQueries({ queryKey: QueryKeys.workSummary.id(id).all }),
    [queryClient]
  );

  return { getWorkSummary, clearCache };
}

export function useWorkSummary(id?: OptionalString, viewer?: ViewerType): UseWorkSummaryResult {
  const queryClient = useQueryClient();
  const queryOptions = useWorkSummaryQueryOptions();

  const removeQueries = useCallback(
    (workSummaryId: OptionalString) =>
      queryClient.removeQueries({ queryKey: QueryKeys.workSummary.id(workSummaryId).all }),
    [queryClient]
  );

  const query = useQuery({ ...queryOptions.build({ id, viewer }), enabled: !!id, staleTime: 180000 });

  const onFetchLatest = useCallback(async () => (await query.refetch()).data, [query]);

  return {
    workSummary: query.data,
    isLoading: !!id && query.isPending,
    fetchLatest: onFetchLatest,
  };
}

export function useWorkSummaries(alias: OptionalString): UseWorkSummariesResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error loading work summaries for candidate ${alias}@` },
    queryKey: QueryKeys.workSummary.alias(alias),
    queryFn: async () => {
      if (!alias) return [];
      const data = await getItems<ListWorkSummariesByAliasQueryVariables, ListWorkSummariesByAliasQuery>({
        query: listWorkSummariesByAlias,
        input: { alias },
      });
      const models = getConcreteModels<Model>(data?.listWorkSummariesByAlias?.items, isValidModel);
      return (models ?? []).map((item) => getItemFromModel(item));
    },
    enabled: !!alias,
  });

  return {
    workSummaries: query.data ?? [],
    isLoading: !!alias && query.isPending,
  };
}

export function useWorkSummaryFiles(): UseWorkSummaryFilesResult {
  const { createItem, deleteItem } = useApiMutation();
  const { getItem } = useApiQuery();

  type AttachParams = Parameters<UseWorkSummaryFilesResult['attachFile']>[0];
  type DetachParams = Parameters<UseWorkSummaryFilesResult['detachFile']>[0];

  const attachFileMutation = useMutation({
    mutationFn: async (input: AttachParams) =>
      createItem<CreateWorkSummaryFilesMutation>(createWorkSummaryFilesMutation, input, ITEM_TYPE),
  });

  const detachFileMutation = useMutation({
    mutationFn: async (params: DetachParams) => {
      let version = params._version;
      if (!version) {
        const wsFile = await getItem<GetWorkSummaryFilesQueryVariables, GetWorkSummaryFilesQuery>({
          query: getWorkSummaryFilesQuery,
          input: { id: params.id },
        });
        version = wsFile?.getWorkSummaryFiles?._version ?? 1;
      }
      const input: DeleteWorkSummaryFilesInput = { id: params.id, _version: version };
      return deleteItem<DeleteWorkSummaryFilesMutation>(deleteWorkSummaryFilesMutation, input, ITEM_TYPE);
    },
  });

  const attachFile = useCallback(
    async (input: AttachParams) => attachFileMutation.mutateAsync(input),
    [attachFileMutation]
  );

  const detachFile = useCallback(
    async (input: DetachParams) => detachFileMutation.mutateAsync(input),
    [detachFileMutation]
  );

  return { attachFile, detachFile };
}

export function useWorkSummaryActions(id?: OptionalString): UseWorkSummaryActionsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { detachFile } = useWorkSummaryFiles();
  const { getWorkSummary, clearCache } = useWorkSummaryLoader();
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();

  type CreateParams = Parameters<WorkSummaryActions['create']>[0];
  type UpdateParams = Parameters<WorkSummaryActions['update']>[0];

  const invalidateQueries = useCallback(
    (workSummaryId: OptionalString, alias: OptionalString) => {
      void queryClient.invalidateQueries({ queryKey: QueryKeys.workSummary.alias(alias) });
      clearCache(workSummaryId);
    },
    [queryClient, clearCache]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const input: CreateWorkSummaryInput = { ...params };
      const result = await createItem<CreateWorkSummaryMutation>(createWorkSummaryMutation, input, ITEM_TYPE);
      return result?.createWorkSummary ? getResourceFromModel(result.createWorkSummary as WorkSummaryModel) : null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully created work summary: ${result?.title}` });
      invalidateQueries(result?.id, result?.alias);
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const workSummary = await getWorkSummary(params.id ?? id);
      if (!workSummary) return null;
      let result: Nullable<UpdateWorkSummaryMutation>;
      if (workSummary) {
        const input: UpdateWorkSummaryInput = {
          ...params,
          id: workSummary.id,
          _version: workSummary.version,
        };
        result = await updateItem<UpdateWorkSummaryMutation>(updateWorkSummaryMutation, input, ITEM_TYPE);
      }
      return result?.updateWorkSummary ? getResourceFromModel(result.updateWorkSummary as WorkSummaryModel) : null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully updated work summary: ${result?.title}` });
      invalidateQueries(result?.id, result?.alias);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      const workSummary = await getWorkSummary(id);
      if (!workSummary) return null;
      let updatedWorkSummary: UpdateWorkSummaryMutation['updateWorkSummary'];
      if (workSummary) {
        const updateInput: UpdateWorkSummaryInput = {
          id: workSummary.id,
          status: WorkSummaryStatus.DELETED,
          _version: workSummary.version,
        };
        const updateResult = await updateItem<UpdateWorkSummaryMutation>(updateWorkSummaryMutation, updateInput);
        updatedWorkSummary = updateResult?.updateWorkSummary;
      }
      if (updatedWorkSummary?.documents?.items.length) {
        const docs = updatedWorkSummary.documents.items.filter((item): item is WorkSummaryFiles => !!item);
        await Promise.allSettled(docs.map(async (doc) => detachFile({ id: doc.id, _version: doc._version })));
      }
      let result: Nullable<DeleteWorkSummaryMutation>;
      if (updatedWorkSummary) {
        const input: DeleteWorkSummaryInput = { id: updatedWorkSummary.id, _version: updatedWorkSummary._version };
        result = await deleteItem<DeleteWorkSummaryMutation>(deleteWorkSummaryMutation, input, ITEM_TYPE);
      }
      return result?.deleteWorkSummary ?? null;
    },
    onSuccess: (result) => {
      addNotification({ type: 'success', header: `Successfully deleted work summary: ${result?.title}` });
      invalidateQueries(result?.id, result?.alias);
    },
  });

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onUpdate = useCallback(async (params: UpdateParams) => updateMutation.mutateAsync(params), [updateMutation]);
  const onDelete = useCallback(async () => deleteMutation.mutateAsync(), [deleteMutation]);

  const actions = useMemo(
    () => ({ create: onCreate, update: onUpdate, delete: onDelete }),
    [onCreate, onUpdate, onDelete]
  );

  return {
    actions,
    isMutating: createMutation.isPending || updateMutation.isPending || deleteMutation.isPending,
  };
}

export function useAdminWorkSummaries(alias: OptionalString, isEnabled = true): UseAdminWorkSummariesResult {
  const { getItems } = useApiQuery();
  const query = useQuery({
    queryKey: QueryKeys.workSummary.alias(alias),
    queryFn: async () => {
      if (!alias) return [];
      const data = await getItems<ListWorkSummariesByAliasQueryVariables, ListWorkSummariesByAliasAdminQuery>({
        query: listWorkSummariesByAliasAdmin,
        input: { alias },
      });
      const models = getConcreteModels<Model>(data?.listWorkSummariesByAlias?.items, isValidAdminModel);
      return (models ?? []).map((item) => getAdminItemFromModel(item));
    },
    enabled: !!alias && isEnabled,
  });

  return {
    workSummaries: query.data ?? [],
    isLoading: !!alias && isEnabled && query.isPending,
  };
}

export const getWorkSummaryItemFromModel = getItemFromModel;
