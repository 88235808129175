import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Form,
  FormField,
  Input,
  Select,
  DatePicker,
  TimeInput,
  Multiselect,
  SpaceBetween,
  Box,
} from '@amzn/awsui-components-react/polaris';

const candidateOptions = [
  { label: 'Alice Johnson (Direct Report)', value: 'alice' },
  { label: 'Bob Smith (Direct Report)', value: 'bob' },
  { label: 'Charlie Brown (Direct Report)', value: 'charlie' },
];

const reviewerOptions = [
  { label: 'David Lee', value: 'david' },
  { label: 'Emma Watson', value: 'emma' },
  { label: 'Frank Miller', value: 'frank' },
  { label: 'Grace Taylor', value: 'grace' },
];

const timezoneOptions = [
  { label: 'UTC', value: 'UTC' },
  { label: 'UTC-5 (EST)', value: 'UTC-5' },
  { label: 'UTC+1 (CET)', value: 'UTC+1' },
  { label: 'UTC+8 (CST)', value: 'UTC+8' },
];

export const enum SessionModalMode {
  CREATE = 'Create',
  EDIT = 'Edit',
}

const DocumentReviewSessionsModal = ({ sessionData, sessionModalMode, isOpen, onClose, onCreate }) => {
  const [createSessionData, setCreateSessionData] = useState({
    candidate: null,
    reviewers: [],
    workdocsLink: '',
    date: '',
    time: '',
    timezone: null,
    chimeLink: '',
  });

  useEffect(() => {
    if (sessionData) {
      setCreateSessionData(sessionData);
    }
  }, [sessionData]);

  const [formErrors, setFormErrors] = useState<any>({});

  const handleCreateSessionChange = (key, value) => {
    setCreateSessionData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    // Clear the error for this field when it's changed
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [key]: undefined,
    }));
  };

  const validateForm = () => {
    const errors: any = {};
    if (!createSessionData.candidate) errors.candidate = 'Candidate is required';
    if (createSessionData.reviewers.length < 2) errors.reviewers = 'At least 2 reviewers are required';
    if (createSessionData.reviewers.length > 4) errors.reviewers = 'Maximum 4 reviewers are allowed';
    if (!createSessionData.workdocsLink) errors.workdocsLink = 'Workdocs link is required';
    if (!createSessionData.date) errors.date = 'Date is required';
    if (!createSessionData.time) errors.time = 'Time is required';
    if (!createSessionData.timezone) errors.timezone = 'Timezone is required';
    return errors;
  };

  const handleCreateSessionSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      onCreate(createSessionData);
      // Reset form data
      setCreateSessionData({
        candidate: null,
        reviewers: [],
        workdocsLink: '',
        date: '',
        time: '',
        timezone: null,
        chimeLink: '',
      });
      setFormErrors({});
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <Modal
      visible={isOpen}
      onDismiss={() => onClose()}
      header={`${sessionModalMode} Document Review Session`}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleCreateSessionSubmit}>
              {sessionModalMode} Session
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <Form>
        <SpaceBetween direction="vertical" size="xs">
          <FormField label="Candidate*" errorText={formErrors.candidate}>
            <Select
              placeholder="Select Candidate"
              selectedOption={createSessionData.candidate}
              onChange={({ detail }) => handleCreateSessionChange('candidate', detail.selectedOption)}
              options={candidateOptions}
            />
          </FormField>
          <FormField
            label="Calibrated Doc Reviewers*"
            errorText={formErrors.reviewers}
            description="Select 2-4 reviewers"
          >
            <Multiselect
              placeholder="Select Reviewers*"
              selectedOptions={createSessionData.reviewers}
              onChange={({ detail }) => handleCreateSessionChange('reviewers', detail.selectedOptions)}
              options={reviewerOptions}
            />
          </FormField>
          <FormField label="Workdocs link to doc artifact" errorText={formErrors.workdocsLink}>
            <Input
              placeholder="Input Workdocs link*"
              value={createSessionData.workdocsLink}
              onChange={({ detail }) => handleCreateSessionChange('workdocsLink', detail.value)}
            />
          </FormField>
          <FormField label="Date and time*" errorText={formErrors.date || formErrors.time || formErrors.timezone}>
            <SpaceBetween direction="horizontal" size="xs">
              <DatePicker
                placeholder="Input Time"
                value={createSessionData.date}
                onChange={({ detail }) => handleCreateSessionChange('date', detail.value)}
              />
              <TimeInput
                placeholder="Input Time"
                value={createSessionData.time}
                onChange={({ detail }) => handleCreateSessionChange('time', detail.value)}
              />
              <Select
                placeholder="Select Time Zone"
                selectedOption={createSessionData.timezone}
                onChange={({ detail }) => handleCreateSessionChange('timezone', detail.selectedOption)}
                options={timezoneOptions}
              />
            </SpaceBetween>
          </FormField>
          <FormField label="Chime link (optional)">
            <Input
              placeholder="Input Chime link"
              value={createSessionData.chimeLink}
              onChange={({ detail }) => handleCreateSessionChange('chimeLink', detail.value)}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </Modal>
  );
};

export default DocumentReviewSessionsModal;
