import React, { useState, useEffect } from 'react';
import { SpaceBetween, Cards } from '@amzn/awsui-components-react/polaris';
import CandidateInfo from './components/CandidateInfo';
import { SESSION_STATES, Vote } from './config';
import DocInfo from './components/DocInfo';
import SessionState from './components/SessionState';
import VotingSection from './components/VotingSection';
import WarningModal from './components/WarningModal';

const mockSessionData = {
  id: 'session123',
  state: SESSION_STATES.FINAL_VOTE,
  candidate: {
    name: 'John Doe',
    role: 'Software Development Engineer',
    level: 'L5',
    tenure: '2 years',
    currentManager: 'Jane Smith',
  },
  reviewers: [
    { id: 'reviewer1', label: 'Alice Johnson', documentReadyVote: Vote.Yes, candidateReadyVote: Vote.Yes },
    { id: 'reviewer2', label: 'Bob Williams', documentReadyVote: Vote.No, candidateReadyVote: Vote.Yes },
    { id: 'reviewer3', label: 'Carol Davis', documentReadyVote: Vote.No, candidateReadyVote: Vote.No },
    { id: 'reviewer4', label: 'Bill Morris', documentReadyVote: Vote.No, candidateReadyVote: Vote.No },
  ],
  workdocsLink: 'https://workdocs.amazon.com/document123',
  chimeLink: 'https://chime.aws/meeting456',
  date: '2024-08-15',
  time: '14:00',
  timezone: { label: 'PDT', value: 'America/Los_Angeles' },
  creator: { id: 'creator1', label: 'David Brown' },
};

const mockCurrentUser = {
  id: 'reviewer2',
  name: 'Bob Williams',
  isCreator: false,
};

const minReviewers = Math.ceil(mockSessionData.reviewers.length / 2);

const ViewDocumentReviewSession = ({
  sessionData = mockSessionData,

  currentUser = mockCurrentUser,
  onUpdateSession = (sessionData: any) => {},
}) => {
  const [localSessionData, setLocalSessionData] = useState<any>(sessionData);
  const [userVote, setUserVote] = useState({ documentReady: null, candidateReady: null });
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [sessionState, setSessionState] = useState(localSessionData.state);
  const isCreator = currentUser.isCreator;

  useEffect(() => {
    // Implement polling logic here to update session data
    const pollInterval = setInterval(() => {
      // Fetch updated session data and call setLocalSessionData
    }, 5000);

    return () => clearInterval(pollInterval);
  }, []);

  const handleSaveStateChange = (newState) => {
    if (newState === SESSION_STATES.COMPLETE) {
      const yesYesVotes = localSessionData.reviewers.filter(
        (reviewer) => reviewer.documentReadyVote === 'Yes' && reviewer.candidateReadyVote === 'Yes'
      ).length;

      if (yesYesVotes > 0 && yesYesVotes < minReviewers) {
        setShowWarningModal(true);
        return;
      }
    }

    onUpdateSession({ ...localSessionData, state: newState });
  };

  const handleStateChange = (newState) => {
    setSessionState(newState);
  };

  const handleVote = (voteType, value) => {
    setUserVote((prevVote) => ({ ...prevVote, [voteType]: value }));
    // Implement logic to send vote to backend
    // For example:
    // onUpdateSession({
    //   ...localSessionData,
    //   reviewers: localSessionData.reviewers.map(reviewer =>
    //     reviewer.id === currentUser.id
    //       ? { ...reviewer, [`${voteType}Vote`]: value }
    //       : reviewer
    //   )
    // });
  };

  const handleModalProceed = () => {
    onUpdateSession({ ...localSessionData, state: SESSION_STATES.COMPLETE });
    setShowWarningModal(false);
  };

  const items = [
    {
      header: 'Candidate Information',
      content: <CandidateInfo candidate={localSessionData.candidate} />,
    },
    {
      header: 'Document Information',
      content: <DocInfo sessionData={localSessionData} />,
    },
    {
      header: 'Session State',
      content: (
        <SessionState
          isCreator={isCreator}
          sessionState={sessionState}
          onStateChange={handleStateChange}
          onSaveStateChange={handleSaveStateChange}
        />
      ),
    },
    {
      header: isCreator ? 'Reviewer Votes' : 'Your Vote',
      content: (
        <VotingSection isCreator={isCreator} sessionData={localSessionData} userVote={userVote} onVote={handleVote} />
      ),
    },
  ];

  return (
    <>
      <WarningModal
        visible={showWarningModal}
        minReviewers={minReviewers}
        onDismiss={() => setShowWarningModal(false)}
        onProceed={handleModalProceed}
      />
      <SpaceBetween direction="vertical" size="l">
        <Cards
          items={items}
          cardDefinition={{
            header: (item) => item.header,
            sections: [
              {
                id: 'content',
                content: (item) => item.content,
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        />
      </SpaceBetween>
    </>
  );
};

export default ViewDocumentReviewSession;
