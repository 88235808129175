// @ts-check
import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import { CrossTableContextProvider } from '@/contexts/CrossTableContext';
import DocumentReviewSessionsTable from '@/components/DocumentReviewSessions/DocumentReviewSessionsTable/DocumentReviewSessionsTable';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">List of Document Review Sessions</Header>;
};

const ListDocumentReviewSessions = () => (
  <CrossTableContextProvider>
    <ContentLayout header={<PageHeader />}>
      <DocumentReviewSessionsTable />
    </ContentLayout>
  </CrossTableContextProvider>
);

export default ListDocumentReviewSessions;
