import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Multiselect from '@amzn/awsui-components-react/polaris/multiselect';
import type { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { LEADERSHIP_PRINCIPLES } from '@/common/constants';
import { MultiSelectionFieldParams } from '@/models';
import { InfoLink } from './links';
import PromoFormField from './PromoFormField';

interface LeadershipPrincipalPanelParams extends MultiSelectionFieldParams {
  label: string;
  description?: string;
}

/**
 * Maps the base list of leadership principles from the API to their corresponding `option`
 */
function mapFlatListToSelectOptions(lps: string[]) {
  if (!lps) return [];
  return LEADERSHIP_PRINCIPLES.filter((lp) => lps?.includes(lp.label));
}

/**
 * Generates the list of options for the multi-select control.
 * The structure of the objects changes whether there are suggested items or not.
 * Suggested/recommended items get grouped.
 */
function getOptions(suggestions: string[] | undefined): SelectProps.Options {
  if (!suggestions) {
    return LEADERSHIP_PRINCIPLES;
  }
  return [
    {
      label: 'Recommended',
      options: LEADERSHIP_PRINCIPLES.filter((lp) => suggestions.includes(lp.label)),
    },
    {
      label: 'Other',
      options: LEADERSHIP_PRINCIPLES.filter((lp) => !suggestions.includes(lp.label)),
    },
  ];
}

const LeadershipPrinciplePanel = ({
  description,
  label,
  onChangeValue,
  onShowTools,
  selections,
  suggestions,
}: LeadershipPrincipalPanelParams): JSX.Element => (
  <Container header={<Header variant="h2">Leadership principles</Header>}>
    <PromoFormField
      label={label}
      description={description}
      info={
        <InfoLink
          onShowTools={() => onShowTools?.({ sectionKey: 'leadershipPrinciples' })}
          ariaLabel="Details on Amazon leadership principles."
        />
      }
    >
      <Multiselect
        options={getOptions(suggestions)}
        placeholder="Choose one or more LPs"
        filteringType="auto"
        selectedOptions={mapFlatListToSelectOptions(selections)}
        onChange={({ detail }) => onChangeValue(detail.selectedOptions.map((opt) => opt.label as string))}
      />
    </PromoFormField>
  </Container>
);

export default LeadershipPrinciplePanel;

// in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest;

  describe('mapFlatListToSelectOptions', () => {
    it('maps correctly', () => {
      expect(mapFlatListToSelectOptions(['Are Right, A Lot', 'Deliver Results'])).toEqual([
        { label: 'Are Right, A Lot', value: '4' },
        { label: 'Deliver Results', value: '14' },
      ]);
    });
  });

  describe('getOptions', () => {
    it('No suggestions', () => {
      expect(getOptions(undefined)).toEqual(LEADERSHIP_PRINCIPLES);
    });

    it('With suggestions', () => {
      expect(getOptions(['Are Right, A Lot', 'Deliver Results'])).toEqual([
        {
          label: 'Recommended',
          options: [
            { label: 'Are Right, A Lot', value: '4' },
            { label: 'Deliver Results', value: '14' },
          ],
        },
        {
          label: 'Other',
          options: LEADERSHIP_PRINCIPLES.filter((lp) => !['Are Right, A Lot', 'Deliver Results'].includes(lp.label)),
        },
      ]);
    });
  });

  describe('LeadershipPrinciplePanel', () => {
    it('renders correctly', () => {
      const wrapper = LeadershipPrinciplePanel({
        label: 'Test',
        description: 'Test',
        onChangeValue: () => {},
        onShowTools: () => {},
        selections: ['Leadership Principle 1', 'Leadership Principle 2'],
        suggestions: ['Leadership Principle 1', 'Leadership Principle 2'],
      });
      expect(wrapper).toMatchSnapshot();
    });
  });
}
