import { Modal, Box, SpaceBetween, Button } from '@amzn/awsui-components-react';

interface WarningModalProps {
  visible: boolean;
  minReviewers: number;
  onDismiss: () => void;
  onProceed: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({ visible, minReviewers, onDismiss, onProceed }) => (
  <Modal
    onDismiss={onDismiss}
    visible={visible}
    header="Warning: Insufficient Yes/Yes Votes"
    footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onProceed}>
            Proceed Anyway
          </Button>
        </SpaceBetween>
      </Box>
    }
  >
    <p>
      There is at least one Yes/Yes vote, but it's below the minimum threshold of {minReviewers} reviewers. Are you sure
      you want to proceed to the Complete state?
    </p>
  </Modal>
);

export default WarningModal;
